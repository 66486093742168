<template>
<!--  <div>-->
<!--    <Home ref="homeRef"/>-->
<!--    <Contents/>-->
<!--    <Footer/>-->
<!--  </div>-->

  <div v-if="openMarkStatus" class="open-mark">
    <img :src="markImg" alt="markImg" style="width: 100%;height: 100vh;">
  </div>
  <router-view></router-view>
</template>

<script setup>
import {computed, createApp, onActivated, onDeactivated, onMounted, ref} from 'vue'
import Home from './components/home/home.vue'
import Footer from './components/footer/footer'
import Contents from './components/contents/contents'

const app = createApp({})
app.component({
  Home,
  Footer,
  Contents
})

const openMarkStatus = ref(false)
const markImg = ref('')
const viewWidth = ref(0)

// 安卓apk
const apkUri = computed(() => {
  return 'https://dxres.wellstout.com/apk/download/dxplay-release-1.12.3-wellstout.apk'
})

// iOS appStore
const appStoreUri = computed(() => {
  return 'https://apps.apple.com/cn/app/%E4%B8%9C%E8%A5%BF%E6%BD%AE%E7%8E%A9/id1672922557'
})

const iOSSchemeUrl = 'wellstout://web'
const andriodSchemeUrl = 'dx://tideplay:9999/mainActivity'

async function initViewport() {
  const width = 750
  const vw = window.innerWidth
  const scale = vw / width
  const content = `width=${width}, initial-scale=${scale}, user-scalable=no`
  let meta = document.querySelector('meta[name=viewport]')

  viewWidth.value = vw

  if (!meta) {
    meta = document.createElement('meta')
    meta.setAttribute('name', 'viewport')
    document.head.appendChild(meta)
  }
  meta.setAttribute('content', content)
}

/**
 * 唤起下载或跳转应用市场
 * type 1:ios 2:android
 * */
function handleDownload(type) {
  const fileName = 'dxcw'
  if (type === 1) {
    handleDownloadFile(appStoreUri.value, fileName, type)
  }
  if (type === 2) {
    handleDownloadFile(apkUri.value, fileName, type)
  }
}

// 下载方法
function handleDownloadFile(url, fileName, type) {
  if (type === 1) {
    window.location.href = url
  }
  if (type === 2) {
    const a = document.createElement('a')
    a.download = fileName
    a.style.display = 'none'
    a.href = url
    document.body.appendChild(a)
    a.click()
    URL.revokeObjectURL(url)
    document.body.removeChild(a)
  }
}

// 用户打开官网网页判断机型以schemeURL打开APP或跳转应用市场、下载apk
async function openApp() {
  if (navigator.userAgent.match(/(iPhone|iPod|iPad);?/i)) {
    if (isWeixin()) {
      setMarkOpen()
    }
    const loadDateTime = new Date()
    // 打开 ios 端软件schemeURL
    window.open(iOSSchemeUrl, '_parent')
    window.setTimeout(function () {
      const timeOutDateTime = new Date()
      if (timeOutDateTime - loadDateTime < 5000) {
        handleDownload(1)
      } else {
        window.close()
      }
    }, 25)
  } else if (navigator.userAgent.match(/android/i)) {
    if (isWeixin()) {
      setMarkOpen()
    }
    const loadDateTime = new Date()
    // 打开 Android 端软件schemeURL
    window.open(andriodSchemeUrl, '_parent')
    window.setTimeout(function () {
      const timeOutDateTime = new Date()
      if (timeOutDateTime - loadDateTime < 5000) {
        handleDownload(2)
      } else {
        window.close()
      }
    }, 25)
  }
}

// 判断微信浏览器
function isWeixin() {
  const ua = navigator.userAgent.toLowerCase()
  return ua.match(/MicroMessenger/i) == "micromessenger"
}

// 打开遮罩层并禁用浏览器页面滚动条
function setMarkOpen() {
  openMarkStatus.value = true
  const bodyEl = document.body.style
  bodyEl.overflow = 'hidden'
  bodyEl.position = 'fixed'
  bodyEl.width = '100%'
}

// 获取图片资源
async function getImg() {
  const img = await import('/public/static/images/h5/openMark.png')
  markImg.value = img.default
}

onMounted(() => {
  if (isWeixin()) {
    setMarkOpen()
  }
  getImg()
  // initViewport()
  // openApp()

})

onActivated(() => {
  // openApp()
})

onDeactivated(() => {
  window.clearTimeout()
})

</script>

<style>
#app {
  position: relative;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /*min-width: 640px !important;*/
  overflow: hidden;
}

/* 整个滚动条 */
::-webkit-scrollbar {
  /* 对应纵向滚动条的宽度 */
  width: 10px;
  /* 对应横向滚动条的宽度 */
  height: 10px;
}

/* 滚动条上的滚动滑块 */
::-webkit-scrollbar-thumb {
  background-color: #796EEF;
  border-radius: 32px;
}

/* 滚动条轨道 */
::-webkit-scrollbar-track {
  background-color: #A09AF2;
  border-radius: 32px;
}

.open-mark {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
}
</style>
