<template>
  <el-row class="footer-container">
    <el-col>
      <div class="footer padding-bottom">
        <div class="img">
          <picture>
            <source :srcset="img2" media="(max-width: 1199px)">
            <img :src="img1" alt="content1">
          </picture>
        </div>
        <div class="line">
          <div></div>
        </div>
        <div class="right">
          <span>版权所有 dx.wellstout.com © 2023-2025 中磊科技(广州)有限公司 粤ICP备2023002943号-1</span>
        </div>
      </div>
    </el-col>
  </el-row>
</template>

<script setup>
import {ref, onMounted} from 'vue'
import {useStore} from "@/store";

const img1 = ref('')
const img2 = ref('')

async function getImg() {
  const image1 = await import('/public/static/images/web/logo-footer@2x.png')
  const image2 = await import('/public/static/images/h5/logo-footer@2x.png')
  img1.value = image1.default
  img2.value = image2.default
}

onMounted(() => {
  getImg()
})
</script>

<style lang="scss" scoped>

.footer {

  width: 100%;
  height: 160px;
  background-color: black;
  margin: 0 auto;
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
}

@media screen and (max-width: 1199px) {
  .img {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 96px;
    line-height: 96px;

    /deep/ img {
      display: flex;
      width: 167px;
      height: 40px;
      line-height: 96px;
    }
  }
}

@media screen and (min-width: 1200px) {
  .img {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 96px;
    line-height: 96px;

    /deep/ img {
      display: flex;
      width: 167px;
      height: 40px;
      line-height: 96px;
    }
  }
}

.line {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  opacity: 0.3;

  div {
    width: 1110px;
    height: 1px;
    background: #FFFFFF;
  }
}

.right {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 63px;
  opacity: 0.5;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  font-size: 14px;
  color: #FFFFFF;
  letter-spacing: -0.3px;
  text-align: center;
}
</style>
