// 引入axios
import axios from 'axios'
import { getToken } from '../utils/auth'

// 创建axios实例
const httpService = axios.create({
  // baseURL: 'http://192.168.1.66:8080/',
  baseURL: '',
  // baseURL: process.env.VUE_APP_BASE_API,
  // 请求超时时间
  timeout: 5000, // 需自定义
  headers: {
    'Content-Type': 'application/json;charset=UTF-8'
  }
})

// request拦截器
httpService.interceptors.request.use(
  config => {
    console.info(config.url)
    // 是否需要设置 token
    const isToken = (config.headers || {}).isToken === false
    if (getToken() && !isToken) {
      config.headers.Authorization = 'Bearer ' + getToken()
    }
    if (config.method == 'get') {
      config.url = encodeURI(config.url)
    }
    return config
  },
  error => {
    // 请求错误处理
    Promise.reject(error)
  }
)

// respone拦截器
httpService.interceptors.response.use(
  response => {
    console.info('interceptors.response', response)
    return response.data
  },
  // 处理处理
  error => {
    // if (error && error.response) {
    //   switch (error.response.status) {
    //   case 400:
    //     error.message = '错误请求'
    //     break
    //   case 401:
    //     error.message = '未授权，请重新登录'
    //     break
    //   case 403:
    //     error.message = '拒绝访问'
    //     break
    //   case 404:
    //     error.message = '请求错误,未找到该资源'
    //     break
    //   case 405:
    //     error.message = '请求方法未允许'
    //     break
    //   case 408:
    //     error.message = '请求超时'
    //     break
    //   case 500:
    //     error.message = '服务器端出错'
    //     break
    //   case 501:
    //     error.message = '网络未实现'
    //     break
    //   case 502:
    //     error.message = '网络错误'
    //     break
    //   case 503:
    //     error.message = '服务不可用'
    //     break
    //   case 504:
    //     error.message = '网络超时'
    //     break
    //   case 505:
    //     error.message = 'http版本不支持该请求'
    //     break
    //   default:
    //     error.message = `未知错误${error.response.status}`
    //   }
    // } else {
    //   error.message = '连接到服务器失败'
    // }
    return Promise.reject(error)
  }
)

/* 网络请求部分 */

/*
 *  get请求
 *  url:请求地址
 *  params:参数
 * */
export function get (url, headers,params = {}) {
  return new Promise((resolve, reject) => {
    httpService({
      url,
      method: 'get',
      headers,
      // params
      // query:params
    }).then(response => {
      console.info('get-response',response)
      resolve(response)
    }).catch(error => {
      reject(error)
    })
  })
}

/*
 *  post请求
 *  url:请求地址
 *  params:参数
 * */
export function post (url, params = {},headers) {
  return new Promise((resolve, reject) => {
    httpService({
      url,
      method: 'post',
      headers,
      data: params

      // params:params
    }).then(response => {
      resolve(response)
    }).catch(error => {
      reject(error)
    })
  })
}

/*
 *  put请求
 *  url:请求地址
 *  params:参数
 * */
export function put (url, params = {}) {
  return new Promise((resolve, reject) => {
    httpService({
      url,
      method: 'put',
      data: params

      // params:params
    }).then(response => {
      resolve(response)
    }).catch(error => {
      reject(error)
    })
  })
}

/*
 *  put请求
 *  url:请求地址
 *  params:参数
 * */
export function putOne (url, params = {}) {
  return new Promise((resolve, reject) => {
    httpService({
      url,
      method: 'put',
      // data: params,
      params
    }).then(response => {
      resolve(response)
    }).catch(error => {
      reject(error)
    })
  })
}

export function del (url, params = {}) {
  return new Promise((resolve, reject) => {
    httpService({
      url,
      method: 'delete',
      data: params// post请求用data
      // params:params
    }).then(response => {
      resolve(response)
    }).catch(error => {
      reject(error)
    })
  })
}

/*
 *  文件上传
 *  url:请求地址
 *  params:参数
 * */
export function fileUpload (url, params = {}) {
  return new Promise((resolve, reject) => {
    httpService({
      url,
      method: 'post',
      data: params,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(response => {
      resolve(response)
    }).catch(error => {
      reject(error)
    })
  })
}

export default {
  get,
  post,
  fileUpload,
  del,
  put,
  putOne
}
