<template>
  <!--  <el-row>-->
  <!--    <el-col class="main">-->
  <div class="main">
    <div class="header-v1">
      <div class="img-box">
        <!--          <picture>-->
        <!--            <source :srcset="bg2" media="(max-width: 1199px)">-->
        <!--            <img :src="bg1" alt="bg" style="width: 100%;">-->
        <!--          </picture>-->

        <div class="main-box">
          <div class="title-box">
            <div class="img">
              <picture>
                <source :srcset="''" media="(max-width: 1199px)">
                <img :src="title1" alt="bg" style="width: 100%;">
              </picture>
            </div>
          </div>

          <div class="input-box">
            <div class="img">
              <img :src="inputBg" alt="bg" style="width: 100%;">
            </div>
            <div class="input-main-box">
              <div class="phone">
                <div class="left">+86</div>
                <div class="right">
                  <el-input v-model="form.phone" clearable placeholder="请输入手机号码"/>
                </div>
              </div>
              <div class="verification-code">
                <div class="left">
                  <el-input v-model="form.verificationCode" clearable placeholder="请输入验证码"/>
                </div>
                <div class="right" @click="getVerifyCode">
                  {{ countDownStatus ? countDown : '获取验证码' }}
                </div>
              </div>
              <div class="submit-btn" @click="handleLogin">
                <div class="btn">注册/登录并领取奖励</div>
              </div>

              <div class="protocol">
                <div class="protocol-box">
                  <input :checked="form.protocol" class="check" type="checkbox" @click="handleCheckBox">
                  我已阅读并同意 <span class="text" @click="handleCheckUserPrivary(1)">《东西潮玩用户协议》</span>
                  <span class="text" @click="handleCheckUserPrivary(2)">《隐私政策》</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="header-v3">
      <div class="img-box">
        <div class="main-box">
          <div class="input-box">
            <div class="input-main-box">
              <div class="phone">
                <div class="left">+86</div>
                <div class="right">
                  <el-input v-model="form.phone" clearable placeholder="请输入手机号码" @keyup.enter.native="onChange"/>
                </div>
              </div>
              <div class="verification-code">
                <div class="left">
                  <el-input v-model="form.verificationCode" clearable placeholder="请输入验证码" @keyup.enter.native="onChange"/>
                </div>
                <div class="right" @click="getVerifyCode">
                  {{ countDownStatus ? countDown : '获取验证码' }}
                </div>
              </div>
              <div class="submit-btn" @click="handleLogin">
                <div class="btn">注册/登录并领取奖励</div>
              </div>

              <div class="protocol">
                <div class="protocol-box">
                  <input :checked="form.protocol" class="check" type="checkbox" @click="handleCheckBox">
                  我已阅读并同意 <span class="text" @click="handleCheckUserPrivary(1)">《东西潮玩用户协议》</span>
                  <span class="text" @click="handleCheckUserPrivary(2)">《隐私政策》</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- h5-footer-s -->
      <div class="h5-footer">
        <div class="footer-box">
          <div class="logo-box">
            <div class="logo"/>
          </div>
          <div class="right-box">
            <div class="info">
              版权所有 dx.wellstout.com © 2023 中磊科技(广州)有限公司 粤ICP备2023002943号-1
            </div>
          </div>
        </div>
      </div>
      <!-- h5-footer-e -->
    </div>
  </div>
  <!--    </el-col>-->
  <!--  </el-row>-->
  <Footer class="main-footer"/>
</template>

<script setup>
import {ref, onMounted, computed, onDeactivated} from 'vue'
import {useRouter, useRoute} from 'vue-router'
import {ElMessage} from 'element-plus'
import Footer from '@/components/footer/footer'
import {sendCode, verifyCode, freeExtractUser} from '../apis'
import request from '../request/index'
import axios from 'axios'
import {removeToken, setToken} from '../utils/auth.js'
import router from '../router'

const route = useRoute()

const bg1 = ref('')
const bg2 = ref('')

const title1 = ref('')
const inputBg = ref('')

const form = ref({
  phone: '',
  verificationCode: '',
  protocol: false
})
const countDownInterval = ref(null)
const countDown = ref(60)
const countDownStatus = ref(false)

const cuurDateTime = computed(() => {
  return Math.round(new Date()) + ''
})

const onChange = (e) => {
  console.log('onChange',e)
  e.srcElement.blur()
}

// 跳转去协议
const handleCheckUserPrivary = (type) => {
  const url = `http://agreement.wellstout.com/#/agreement?type=${type}`
  window.open(url, '_parent')
}

const deviceId = computed(() => {
  const date = Math.round(new Date()) + ''
  console.log('date', date)
  return date.substring(5, 13) + 'H5'
})

const handleCheckBox = () => {
  form.value.protocol = !form.value.protocol
  console.log('handleCheckBox', form.value)
}

const countDownFn = () => {
  countDownInterval.value = setInterval(() => {
    countDownStatus.value = true
    if (countDown.value === 0) {
      countDownStatus.value = false
      clearInterval(countDownInterval.value)
      countDown.value = 60
    } else {
      countDown.value = countDown.value - 1
    }
  }, 1000)
}

async function getBgImg() {
  const bgImg1 = await import('../public/static/images/web/bg-free.webp')
  const bgImg2 = await import('../public/static/images/h5/bg-free.webp')
  const titleImg1 = await import('../public/static/images/web/bg-free-title.png')
  const inputBgImg1 = await import('../public/static/images/web/bg-input1.png')
  bg1.value = bgImg1.default
  bg2.value = bgImg2.default
  title1.value = titleImg1.default
  inputBg.value = inputBgImg1.default
}

// 发送验证码
const getVerifyCode = () => {
  console.log('getFuli-id, type', route.params, deviceId)
  if (countDownStatus.value) return
  const phone = form.value.phone
  if (phone === '') {
    ElMessage({
      message: '请输入手机号',
      type: 'warning'
    })
    return
  } else {
    const reg = /^1[3|4|5|6|7|8|9][0-9]\d{8}$/
    console.log('reg.test(phone)', reg.test(phone))
    if (!reg.test(phone)) {
      ElMessage({
        message: '请输入正确手机号',
        type: 'warning'
      })

      return
    }
  }
  // 倒计时
  countDownFn()
  // 发送验证码

  // const url = '/dev-api/api/user/send'
  // const url = `/dev-api/api/user/send/${phone}/1?deviceId=${deviceId.value}`
  const url = `/stage-api/api/user/send/${phone}/1?deviceId=${deviceId.value}`

  // fetch
  request.get(url, {
    'appType': 'h5'
  }).then(res => {
    console.log('sendCode', res)

    if (res.code === 200) {
      ElMessage({
        message: '验证码发送成功',
        type: 'success'
      })
    }
  }).catch(err => {
    console.log('err', err)
    if (err) {
      ElMessage({
        message: '出错啦~请检查后重新再试！',
        type: 'warning'
      })

      countDownStatus.value = false
      clearInterval(countDownInterval.value)
      countDown.value = 60
    }
  })
}

const handleLogin = () => {
  console.log('handleLogin', form.value)
  const {
    phone,
    verificationCode,
    protocol
  } = form.value

  if (phone === '') {
    ElMessage({
      message: '请输入手机号',
      type: 'warning'
    })
    return
  } else {
    const reg = /^1[3|4|5|6|7|8|9][0-9]\d{8}$/
    console.log('reg.test(phone)', reg.test(phone))
    if (!reg.test(phone)) {
      ElMessage({
        message: '请输入正确手机号',
        type: 'warning'
      })

      return
    }
  }

  if (verificationCode === '') {
    ElMessage({
      message: '请输入验证码',
      type: 'warning'
    })
    return
  }

  if (!protocol) {
    ElMessage({
      message: '请勾选协议再登录',
      type: 'warning'
    })
    return
  }

  const params = {
    // deviceId: deviceId.value,
    mobile: phone,
    verifyCode: verificationCode
  }
  console.log('params', params)
  // step1: 先校验登录
  // step2: 登录成功后领取福利
  //

  // const url = '/dev-api/auth/web/verifyLogin'
  const url = `/stage-api/auth/web/verifyLogin?deviceId=${deviceId.value}`

  request.post(url, params, {
    'Content-Type': 'application/json',
    'appType': 'h5'
  }).then(res => {
    const {code, data, msg} = res

    console.log('verifyCode-code', code)
    if (code === 200) {
      setToken(data.token)
      // 登录成功去领取福利
      setTimeout(() => {
        getFuli()
      }, 1000)
    } else {
      ElMessage({
        message: msg,
        type: 'warning'
      })
    }
  })
}

const getFuli = () => {
  const {id, type} = route.params
  console.log('getFuli-id, type', route.params)
  if (!id) {
    ElMessage({
      message: '玩法ID不能为空',
      type: 'warning'
    })

    return
  }
  const params = {
    mobile: form.value.phone,
    caveMusicId: id,
    type: type + ''
  }
  console.log('params', params)
  // const url = '/dev-api/playway/freeExtract/freeExtractUser'
  const url = `/stage-api/playway/freeExtract/freeExtractUser?deviceId=${deviceId.value}`

  request.post(url, JSON.stringify(params), {
    'Content-Type': 'application/json',
    'appType': 'h5'
  }).then(res => {
    console.log('freeExtractUser-res', res)
    const {code, status, msg} = res
    if (code === 200) {
      // const tips = status === 0 ? '领取失败，该号码已领取过此福利' : '领取成功,即将前往东西潮玩APP'
      ElMessage({
        message: msg,
        type: 'success',
        showClose: true,
        duration: 5000
      })

      // 重定向至首页并显示打开app
      setTimeout(() => {
        router.push('/')
      }, 5050)
    } else {
      ElMessage({
        message: '领取失败，稍后重试!',
        type: 'warning'
      })
    }
  })
}

onMounted(() => {
  removeToken()
  getBgImg()
})
onDeactivated(() => {
  clearInterval(countDownInterval.value)
})
</script>

<style lang="scss" scoped>
.main {
  position: relative;
  width: 100vw;
  height: 100vh;
  //display: flex;
  //justify-content: center;
  //align-items: center;
  overflow: hidden;
}

@media screen and (max-width: 1199px) {
  .header-v1 {
    display: none !important;
  }

  .header-v3 {
    display: block !important;
  }
  .main-footer {
    display: none !important;
  }
}

@media screen and (min-width: 1281px) {
  .header-v3 {
    display: none !important;
  }
}

@media screen and (max-height: 800px) {
  .header-v3  {
    .h5-footer {
      display: none !important;
    }
  }
}

@media screen and (max-width: 1540px) {
  .header-v1 {
    .phone {

      .left {
        font-size: 8px !important;
      }

      .right {
        ::v-deep .el-input {
          font-size: 8px !important;
        }
      }
    }

    .verification-code {
      .left {
        ::v-deep .el-input {
          font-size: 8px !important;
        }
      }

      .right {
        font-size: 8px !important;
      }
    }

    .submit-btn .btn {
      font-size: 8px !important;
    }

    .protocol-box {
      font-size: 8px !important;
    }
  }
}

.img {
  width: 100%;
  height: 100%;

  /deep/ img {
    width: 100%;
    height: 100%;
  }
}

.header-v1 {
  position: relative;
  width: 100vw;
  height: 100vh;
  background-image: url("/public/static/images/web/bg-free.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  margin: 0 auto;
  background-attachment: fixed;

  .img-box {
    position: relative;
    width: 100vw;
    height: 100%;

    /deep/ img {
      width: 100%;
      height: 100%;
    }

    .main-box {
      position: absolute;
      top: -40px;
      width: 100vw;
      height: 100%;

      .title-box {
        width: calc(57.2%);
        height: calc(61.3%);
        //width: 1100px;
        //height: 663px;
        margin: 0 auto;

        /deep/ img {
          width: calc(100%) !important;
          height: calc(100%) !important;
          //width: 1100px !important;
          //height: 663px;
        }
      }

      .input-box {
        position: relative;
        width: calc(37.5%);
        height: calc(36%);
        margin: 0 auto;

        /deep/ img {
          width: calc(100%) !important;
          height: calc(100%) !important;
        }

        .input-main-box {
          position: absolute;
          top: calc(13.3%);
          left: calc(25%);
          width: calc(50%);
          height: calc(74.4%);

          .phone {
            width: calc(100%);
            height: calc(24%);
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: calc(4%);
            background-image: url("/public/static/images/web/bg-input2.png");
            background-repeat: no-repeat;
            background-size: 100% 100%;

            .left {
              width: calc(18%);
              //height: calc(100%);
              line-height: calc(100%);
              font-family: PingFangSC-Regular;
              font-weight: 400;
              font-size: 16px;
              color: #333333;
              letter-spacing: 0;
              margin-left: calc(2%);
            }

            .right {
              width: calc(82%);
              height: calc(100%);
              line-height: calc(100%);

              ::v-deep .el-input {
                height: calc(100% - 2px) !important;
                --el-input-height: calc(100% - 2px) !important;
              }

              ::v-deep .el-input__wrapper {
                box-shadow: none !important;
              }

              ::v-deep .el-input__wrapper:hover {
                box-shadow: none !important;
              }

              ::v-deep .el-input__wrapper:is-foucs {
                box-shadow: none !important;
              }
            }
          }

          .verification-code {
            width: calc(100%);
            height: calc(24%);
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: calc(8%);
            background-image: url("/public/static/images/web/bg-input2.png");
            background-repeat: no-repeat;
            background-size: 100% 100%;

            .left {
              width: calc(57.2%);
              height: calc(100%);
              line-height: calc(100%);
              margin-left: calc(5.5%);

              ::v-deep .el-input {
                height: calc(100% - 2px) !important;
                --el-input-height: calc(100% - 2px) !important;
              }

              ::v-deep .el-input__wrapper {
                padding: 0 !important;
                box-shadow: none !important;
              }

              ::v-deep .el-input__inner {
                padding: 0 !important;
              }

              ::v-deep .el-input__wrapper:hover {
                box-shadow: none !important;
              }

              ::v-deep .el-input__wrapper:is-foucs {
                box-shadow: none !important;
              }
            }

            .right {
              width: calc(32.8%);
              line-height: calc(100%);
              font-family: PingFangSC-Medium;
              font-weight: 500;
              font-size: 14px;
              color: #825DFF;
              letter-spacing: 0;
              cursor: pointer;
              user-select: none;
            }
          }

          .submit-btn {
            width: calc(100%);
            height: calc(26%);
            display: flex;
            justify-content: center;
            align-items: center;
            background-image: url("/public/static/images/web/bg-input3.png");
            background-repeat: no-repeat;
            background-size: 100% 100%;

            .btn {
              width: calc(100%);
              font-family: PingFangSC-SNaNpxibold;
              font-weight: 600;
              font-size: 16px;
              color: #FFFFFF;
              letter-spacing: 0.6px;
              cursor: pointer;
            }

          }

          .protocol {
            width: calc(100%);
            height: calc(18%);
            display: flex;
            justify-content: center;
            align-items: center;

            .protocol-box {
              height: calc(100%);
              display: flex;
              justify-content: center;
              align-items: center;
              color: #999999;
              font-size: 12px;
              font-face: PingFangSC;
              font-weight: 400;
              line-height: calc(100%);
              letter-spacing: 0;
              paragraph-spacing: 0;

              .text {
                color: #815DFF;
                cursor: pointer;
              }

              .check {
                /*清除默认样式checkbox，webkit为谷歌内核，moz为火狐内核 */
                -webkit-appearance: none;
                -moz-appearance: none;
                /*去除边框 */
                outline: none;
                width: 14px;
                height: 14px;
                background: #ffffff;
                border: 1px solid #dddddd;
                /*将边框设置为原型 */
                border-radius: 50%;
                /*相对定位 */
                position: relative;
                /* transition: background 0.1s;更改颜色的过渡 */
                top: -1px;
                right: 4px;
                cursor: pointer;
              }

              .check:checked {
                background: #815DFF;
              }

              .check:checked::after { /*添加一个伪类选择器，通过定位，相当于给复选框里边加一个内容 */
                content: '';
                height: 3px;
                width: 6px;
                border: 2px solid #fff;
                position: absolute;
                top: 2px; /*top和left是用来定位的 */
                left: 2px;
                border-top: none;
                border-right: none;
                transform: rotate(-45deg); /*通过矩形翻转，以及去掉两个位置的边框实现打钩效果（毕竟矩形四条边，只剩相邻两条边就像一个勾了 */
              }
            }
          }
        }
      }
    }
  }
}

.header-v3 {
  position: relative;
  //width: 390px;
  //max-width: 750px;
  //height: 100%;
  width: 100vw;
  height: 100vh;
  //min-width: 750px;
  //min-height: 800px;
  background-image: url("/public/static/images/h5/bg-free.png");
  background-repeat: no-repeat;
  background-size: 100vw auto;
  background-attachment: fixed;
  margin: 0 auto;
  overflow: hidden;

  .img-box {
    position: relative;
    width: 390px;
    max-width: 750px;
    //width: 100vw;
    height: 100%;
    min-height: 800px;

    /deep/ img {
      //width: 390px;
      //max-width: 750px;
      width: 100%;
      height: 100%;
    }

    .main-box {
      position: absolute;
      top: calc(36.9%);
      //width: 390px;
      //max-width: 750px;
      width: 100vw;
      height: 100%;

      .input-box {
        position: relative;
        width: calc(82.9%);
        height: calc(22.6%);
        //width: 720px;
        //height: 360px;
        margin: 0 auto;

        .input-main-box {
          position: absolute;
          top: 0;
          left: 0;
          //width: calc(82.9%);
          width: calc(100%);
          height: calc(100%);

          .phone {
            width: calc(100%);
            height: calc(24%);
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-bottom: calc(4%);
            background-image: url("/public/static/images/web/bg-input2.png");
            background-repeat: no-repeat;
            background-size: 100% 100%;

            .left {
              width: calc(18%);
              //height: calc(100%);
              line-height: calc(100%);
              font-family: PingFangSC-Regular;
              font-weight: 500;
              font-size: 16px;
              color: #333333;
              letter-spacing: 0;
              margin-left: calc(2%);
            }

            .right {
              width: calc(75%);
              height: calc(100%);
              line-height: calc(100%);

              ::v-deep .el-input {
                height: calc(100% - 2px) !important;
                --el-input-height: calc(100% - 2px) !important;
              }

              ::v-deep .el-input__wrapper {
                box-shadow: none !important;
              }

              ::v-deep .el-input__wrapper:hover {
                box-shadow: none !important;
              }

              ::v-deep .el-input__wrapper:is-foucs {
                box-shadow: none !important;
              }
            }
          }

          .verification-code {
            width: calc(100%);
            height: calc(24%);
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: calc(8%);
            background-image: url("/public/static/images/web/bg-input2.png");
            background-repeat: no-repeat;
            background-size: 100% 100%;

            .left {
              width: calc(57.2%);
              height: calc(100%);
              line-height: calc(100%);
              margin-left: calc(5.5%);

              ::v-deep .el-input {
                height: calc(100% - 2px) !important;
                --el-input-height: calc(100% - 2px) !important;
              }

              ::v-deep .el-input__wrapper {
                padding: 0 !important;
                box-shadow: none !important;
              }

              ::v-deep .el-input__inner {
                padding: 0 !important;
              }

              ::v-deep .el-input__wrapper:hover {
                box-shadow: none !important;
              }

              ::v-deep .el-input__wrapper:is-foucs {
                box-shadow: none !important;
              }
            }

            .right {
              width: calc(32.8%);
              line-height: calc(100%);
              font-family: PingFangSC-Medium;
              font-weight: 500;
              font-size: 14px;
              color: #825DFF;
              letter-spacing: 0;
              cursor: pointer;
              user-select: none;
            }
          }

          .submit-btn {
            width: calc(100%);
            height: calc(26%);
            display: flex;
            justify-content: center;
            align-items: center;
            background-image: url("/public/static/images/web/bg-input3.png");
            background-repeat: no-repeat;
            background-size: 100% 100%;

            .btn {
              width: calc(100%);
              font-family: PingFangSC-SNaNpxibold;
              font-weight: 600;
              font-size: 16px;
              color: #FFFFFF;
              letter-spacing: 0.6px;
              cursor: pointer;
            }

          }

          .protocol {
            width: calc(100%);
            height: calc(18%);
            display: flex;
            justify-content: center;
            align-items: center;

            .protocol-box {
              height: calc(100%);
              display: flex;
              justify-content: center;
              align-items: center;
              color: #FFFFFF;
              font-size: 12px;
              font-face: PingFangSC;
              font-weight: 400;
              line-height: calc(100%);
              letter-spacing: 0;
              paragraph-spacing: 0;

              .text {
                color: #815DFF;
                cursor: pointer;
              }

              .check {
                /*清除默认样式checkbox，webkit为谷歌内核，moz为火狐内核 */
                -webkit-appearance: none;
                -moz-appearance: none;
                /*去除边框 */
                outline: none;
                width: 14px;
                height: 14px;
                background: #ffffff;
                border: 1px solid #dddddd;
                /*将边框设置为原型 */
                border-radius: 50%;
                /*相对定位 */
                position: relative;
                /* transition: background 0.1s;更改颜色的过渡 */
                top: -1px;
                right: 4px;
                cursor: pointer;
              }

              .check:checked {
                background: #815DFF;
              }

              .check:checked::after { /*添加一个伪类选择器，通过定位，相当于给复选框里边加一个内容 */
                content: '';
                height: 3px;
                width: 6px;
                border: 2px solid #fff;
                position: absolute;
                top: 2px; /*top和left是用来定位的 */
                left: 2px;
                border-top: none;
                border-right: none;
                transform: rotate(-45deg); /*通过矩形翻转，以及去掉两个位置的边框实现打钩效果（毕竟矩形四条边，只剩相邻两条边就像一个勾了 */
              }
            }
          }
        }
      }
    }
  }

  .h5-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100vw;
    height: calc(12.3%);
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url("/public/static/images/h5/bg-free-footer.png");
    background-repeat: no-repeat;
    background-size: 100vw 100%;

    .footer-box {
      position: relative;
      width: 100vw;
      height: 100%;
      margin: 0 auto;

      .logo-box {
        width: 100vw;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;

        .logo {
          width: 134px;
          height: 32px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          background-image: url("/public/static/images/h5/logo-footer2.png");
          background-repeat: no-repeat;
          background-size: 134px 32px;
        }
      }

      .right-box {
        width: 100vw;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: baseline;

        .info {
          width: calc(85%);
          height: calc(36%);
          font-family: PingFangSC-Regular;
          font-weight: 400;
          font-size: 12px;
          color: #FFFFFF;
          letter-spacing: 0;
          text-align: center;
          line-height: 18px;
        }
      }
    }
  }
}
</style>
