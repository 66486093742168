import { createRouter, createWebHashHistory,createWebHistory } from 'vue-router'

import Home from '../views/home.vue'
import FreeExtract from '../views/free-extract.vue'
import Download from '../views/download'

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/free-extract/:id?/:type?',
    name: 'free-extract',
    component: FreeExtract
  },
  {
    path: '/download',
    name: 'download',
    component: Download
  }
]

const router = createRouter({
  // history: createWebHistory('/'),
  history: createWebHashHistory('/'),
  // base: process.env.BASE_URL,
  routes
})

export default router
