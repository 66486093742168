import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import App from './App.vue'
import { createPinia } from 'pinia'
import router from "../router";
import '@/style/index.scss'

const app = createApp(App)

app.use(router) // 注册路由
app.use(ElementPlus)
app.use(createPinia())
app.mount('#app')
