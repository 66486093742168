import { defineStore } from 'pinia'

export const useStore = defineStore('main', {
  state: () => {
    return {
      // 所有这些属性都将自动推断其类型
      // 定义一个是否展开的字段isCollapse
      isResize: false
    }
  }
})
