<template>
  <el-row>
    <el-col>
      <el-space style="width: 100%;" direction="vertical" alignment="center">
        <el-skeleton style="width: 100%; margin-bottom: 20px" :loading="loading" animated :count="4">
          <template #template>
            <el-skeleton-item variant="image" style="width: 300px; height: 300px;margin: 20px"/>
            <div style="padding: 20px">
              <el-skeleton-item variant="h3" style="width: 50%"/>
              <div style="
              display: flex;
              align-items: center;
              justify-content: space-between;
              margin-top: 16px;
              height: 16px;
            "
              >
                <el-skeleton-item variant="text" style="margin-right: 16px"/>
                <el-skeleton-item variant="text" style="width: 30%"/>
              </div>
            </div>
          </template>
          <template #default>
            <div class="contents">
              <div class="header">
                <div class="content">
                  <picture>
                    <source media="(max-width: 1199px)" :srcset="img5">
                    <img :src="img1" alt="content1" style="width: 100%;display: flex;">
                  </picture>
                </div>
                <div class="content">
                  <picture>
                    <source media="(max-width: 1199px)" :srcset="img6">
                    <img :src="img2" alt="content2" style="width: 100%;display: flex;">
                  </picture>
                </div>
                <div class="content">
                  <picture>
                    <source media="(max-width: 1199px)" :srcset="img7">
                    <img :src="img3" alt="content3" style="width: 100%;display: flex;">
                  </picture>
                </div>
                <div class="content">
                  <picture>
                    <source media="(max-width: 1199px)" :srcset="img8">
                    <img :src="img4" alt="content4" style="width: 100%;display: flex;">
                  </picture>
                </div>
              </div>
            </div>
          </template>
        </el-skeleton>
      </el-space>
    </el-col>
  </el-row>
</template>

<script setup>
import {ref, onMounted} from 'vue'

const loading = ref(true)

const img1 = ref('')
const img2 = ref('')
const img3 = ref('')
const img4 = ref('')
const img5 = ref('')
const img6 = ref('')
const img7 = ref('')
const img8 = ref('')

async function getImg() {
  loading.value = true
  const contentWeb1 = await import('/public/static/images/web/Content1@2x.webp')
  const contentWeb2 = await import('/public/static/images/web/Content2@2x.webp')
  const contentWeb3 = await import('/public/static/images/web/Content3@2x.webp')
  const contentWeb4 = await import('/public/static/images/web/Content4@2x.webp')

  const contentH51 = await import('/public/static/images/h5/Content1@2x.webp')
  const contentH52 = await import('/public/static/images/h5/Content2@2x.webp')
  const contentH53 = await import('/public/static/images/h5/Content3@2x.webp')
  const contentH54 = await import('/public/static/images/h5/Content4@2x.webp')

  img1.value = contentWeb1.default
  img2.value = contentWeb2.default
  img3.value = contentWeb3.default
  img4.value = contentWeb4.default

  img5.value = contentH51.default
  img6.value = contentH52.default
  img7.value = contentH53.default
  img8.value = contentH54.default

  setTimeout(() => {
    loading.value = false
    windowScrollTop()
  }, 1000)
}

// 滚动条回到顶部
function windowScrollTop() {
  if (!window.scrollTo) {
    window.scrollTo = function (opts) {
      window.screenLeft = opts.left
      window.screenTop = opts.top
    }
    window.scrollTo({left: 0, top: 0})
  }
  window.scrollTo(0, 0)
}

onMounted(() => {
  loading.value = false
  getImg()
})
</script>

<style scoped lang="scss">
.el-space {
  background-color: #f1f3f4;
}

.el-space /deep/ .el-space__item {
  padding-bottom: 0 !important;
}

.contents {
  width: 100%;
  background-color: rgb(241, 243, 244);
  margin: 0 auto;
}

@media screen and (max-width: 1199px) {
  .header {
    .content {
      width: 100%;
      height: 100%;
      padding: 0 !important;
    }
  }
}

@media screen and (min-width: 1200px) {
  .header {
    .content {
      width: auto;
      padding: 80px 120px 0 120px !important;

      /deep/ img {
        width: auto;
        height: 100%;
      }
    }

    .content:last-child {
      padding-bottom: 80px !important;
    }
  }
}
</style>
